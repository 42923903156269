import logo from "./logo.webp"

const  clientData = {
   client_entity: 16,
   attorney_firm: 'bizheadlaw.com',
    attorney_name: 'Kevin Chern',
    attorney_number: '(312) 899-6175',
    attorney_email: 'kevin@bizheadlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_biz_head_law+(720p).mp4',
    mainColor: 'white',
    secondaryColor: '#f49231',
    siteLink: 'http://www.bizheadlaw.com/',
    logo
}

export default clientData